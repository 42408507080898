import React, { useState, useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import AppHeading from 'components/AppHeading'
import FeaturedWebinar from './FeaturedWebinar'
import WebinarTabs from './WebinarTabs'
import WebinarItem from './WebinarItem'
import { getCurrentTimeByDefaultTz, isLiveEventStatus } from 'utils'
import { getFeaturedWebinars, getDatesFromWebinarEvents, getWebinarsByLang } from 'utils/common'
import {
  WEBINAR_TYPES,
  TRACKING,
  MAP_DB_LANGUAGES,
  LANG_EN,
  TAB_DATE_FORMAT,
} from 'config/constants/common'
import { useAuth } from 'store/Auth'
import { useAnalysis } from 'store/Analysis'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import './index.scss'

let timeOutLoading
let timeOutUpdateData = null
let timeIntervalUpdateData = null
const today = moment(getCurrentTimeByDefaultTz()).format('YYYY-MM-DD')

export default function Webinars(props) {
  const { t, i18n } = useTranslation(['webinars', 'common'])
  const { updateClientTzData } = useAuth()
  const { webinarsViews, getListWebinarsViews } = useAnalysis()
  const [tabActiveIndex, setTabActiveIndex] = useState(0)
  const [selectedDate, setSelectedDate] = useState(today)
  const [isLoading, setLoading] = useState(true)
  const [isUpdateEvent, setUpdateEvent] = useState(true)
  const { data } = props
  const { postgres } = data || {}
  const { allEcmsEventWebinars } = postgres || {}
  const { nodes: eventWebinars = [] } = allEcmsEventWebinars || {}
  const { language: currentLang = LANG_EN } = i18n || {}
  const trackingEventSource = TRACKING.SOURCE.WEBINARS
  /** START UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */
  const [featuredWebinarTimeHold, setFeaturedWebinarTimeHold] = useState('');
  /** END UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListWebinarsViews)
    }

    fetchApis()
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // GET WEBINARS BY CURRENT LANGUAGE
  const webinarsByLang = useMemo(() => {
    return getWebinarsByLang(eventWebinars, MAP_DB_LANGUAGES[currentLang], {
      filterTypes: [WEBINAR_TYPES.NON_PANEL, WEBINAR_TYPES.PANEL],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventWebinars, currentLang])

  // GET LIST DATES
  const listDates = useMemo(() => {
    return getDatesFromWebinarEvents(webinarsByLang, {
      dateFormat: TAB_DATE_FORMAT,
    })
  }, [webinarsByLang])

  // GROUP WEBINAR TYPES
  const arrWebinars = useMemo(() => {
    const validEventWebinars = webinarsByLang
      .filter((webinar) => {
        const { startDate, endDate } = webinar || {}

        return isLiveEventStatus(startDate, endDate, {
          currentTime: selectedDate,
          timeFormat: 'YYYY-MM-DD',
          isOnlyCheckDate: true,
        })
      })
      .map((webinar) => {
        const { ecmsEventFilesByWebinarId } = webinar || {}
        const { nodes: webinarEventFiles = [] } = ecmsEventFilesByWebinarId || {}

        return {
          ...webinar,
          ecmsEventFilesByWebinarId: webinarEventFiles,
        }
      })

    return validEventWebinars
  }, [webinarsByLang, selectedDate])

  // Get featured webinar
  const featuredWebinar = useMemo(() => {
    /** START UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */
    // return getFeaturedWebinars(arrWebinars) || {};
    const webinars = getFeaturedWebinars(arrWebinars) || {};
    if (featuredWebinarTimeHold !== webinars?.webinarId) {
      setFeaturedWebinarTimeHold(webinars?.webinarId);
    }
    return webinars;
    /** END UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrWebinars, isUpdateEvent])

  /** START UPDATE CHANGE REQUEST [Move the webinar to the top of list] ON 2021-08-30 */
  const smoothWebinars  = useMemo(() => {
    const webinarTimeHold = arrWebinars.filter(s => s.webinarId === featuredWebinarTimeHold) || [];
    const webinars = arrWebinars.filter(s => s.webinarId !== featuredWebinarTimeHold) || [];
    return webinarTimeHold.concat(webinars);
  }, [arrWebinars, featuredWebinarTimeHold])
  /** END UPDATE CHANGE REQUEST [Move the webinar to the top of list] ON 2021-08-30 */

  useEffect(() => {
    if (isLoading) {
      timeOutLoading = setTimeout(() => setLoading(false), 200)
    }

    return () => {
      clearTimeout(timeOutLoading)
    }
  }, [arrWebinars, isLoading])

  // Run every minute to update event's status
  useEffect(() => {
    const everySeconds = 60

    let currentSeconds = moment().format('s')
    currentSeconds = currentSeconds >= everySeconds ? 0 : currentSeconds
    const restSeconds = everySeconds - currentSeconds

    // Update data by every minute
    function updateDataByEveryMinute() {
      setUpdateEvent((isUpdateEvent) => !isUpdateEvent)
      updateClientTzData()
    }

    // set timeout call request api
    timeOutUpdateData = setTimeout(() => {
      if (restSeconds !== 0) {
        updateDataByEveryMinute()
      }

      timeIntervalUpdateData = setInterval(() => {
        updateDataByEveryMinute()
      }, everySeconds * 1000)
    }, restSeconds * 1000)

    return () => {
      clearInterval(timeIntervalUpdateData)
      clearTimeout(timeOutUpdateData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let activeDateIndex = listDates.findIndex((date) => date.value === today)
    activeDateIndex = activeDateIndex < 0 ? 0 : activeDateIndex
    const selectedDate = listDates[activeDateIndex] || {}
    const { value = '' } = selectedDate
    setTabActiveIndex(activeDateIndex)
    setSelectedDate(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDates])

  return (
    <div className={'Webinars-area Layout-children'}>
      <SEO title={t('lbl_title_en')} />
      <AppHeading title={t('lbl_title_en')} />
      <div className="Webinars-tabs">
        <WebinarTabs
          listDates={listDates}
          tabActiveIndex={tabActiveIndex}
          setTabActiveIndex={setTabActiveIndex}
          setSelectedDate={setSelectedDate}
        />
        {!isEmpty(featuredWebinar) && (
          <FeaturedWebinar data={featuredWebinar} isUpdateEvent={isUpdateEvent} />
        )}
      </div>
      <Container>
        <div className="Webinars-container">
          {/** START UPDATE CHANGE REQUEST [Move the webinar to the top of list] ON 2021-08-30 */}
          {/* {!isEmpty(smoothWebinars) ? ( */}
          {!isEmpty(arrWebinars) ? (
            <div className="WebinarsPanel-container">
              {/* {smoothWebinars.map((webinar, index) => ( */}
              {arrWebinars.map((webinar, index) => (
                /** END UPDATE CHANGE REQUEST [Move the webinar to the top of list] ON 2021-08-30 */
                <WebinarItem
                  key={index}
                  index={index + 1}
                  webinar={webinar}
                  views={webinarsViews[webinar.webinarId]}
                  isUpdateEvent={isUpdateEvent}
                  /** START UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */
                  featuredWebinarTimeHold={featuredWebinarTimeHold}
                  /** END UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */
                />
              ))}
            </div>
          ) : (
            <>{!isLoading && <p className="Webinars-msg">{t('lbl_no_webinars')}</p>}</>
          )}
        </div>
      </Container>
    </div>
  )
}

export const query = graphql`
  query($eventId: Int!) {
    postgres {
      allEcmsEventWebinars(orderBy: START_DATE_ASC, condition: { eventId: $eventId }) {
        nodes {
          webinarId
          webinarName
          webinarType
          webinarDescription
          zoomLink
          meetingId
          meetingPwd
          languageCode
          eventId
          panelId
          startDate
          endDate
          ecmsUserByHostName {
            displayName
            userName
            position
            ecmsOrganisationByOrgId {
              orgName
            }
          }
          ecmsLanguageByLanguageCode {
            languageName
          }
          ecmsTimezoneByTimezoneId {
            timezoneCode
          }
          ecmsEventFilesByWebinarId {
            nodes {
              id
              eventId
              fileCategory
              fileCode
              fileDescription
              fileId
              fileSize
              fileType
              fileName
              keyCode
              url
            }
          }
        }
      }
    }
  }
`
