import React, { useMemo } from 'react'
import './index.scss'

export default function WebinarTabsMobile(props) {
  const {
    listDates = [],
    tabActiveIndex = 0,
    setTabActiveIndex = () => {},
    setSelectedDate = () => {},
  } = props
  const maxIndex = listDates.length - 1

  const label = useMemo(() => {
    const tabSelected = listDates[tabActiveIndex] || {}

    return typeof tabSelected === 'object' ? tabSelected.label : tabSelected
  }, [listDates, tabActiveIndex])

  /**
   * On navigate date
   */
  function onNavigateDate(tabIndex) {
    tabIndex = tabIndex < maxIndex ? tabIndex : maxIndex
    tabIndex = tabIndex < 0 ? 0 : tabIndex

    // get value
    const selectedDate = listDates[tabIndex] || {}
    const { value = '' } = selectedDate

    setTabActiveIndex(tabIndex)
    setSelectedDate(value)
  }

  return (
    <div className="WebinarTabsMobile-container">
      <div className="WebinarTabsMobile-navigate">
        {!!tabActiveIndex && (
          <span
            className="WebinarTabsMobile-btn"
            onClick={() => onNavigateDate(tabActiveIndex - 1)}
          >
            <span className="Icon-ic Icon-arrow-left WebinarTabsMobile-icon" />
          </span>
        )}
      </div>
      <span className="WebinarTabsMobile-date">{label}</span>
      <div className="WebinarTabsMobile-navigate">
        {tabActiveIndex < maxIndex && (
          <span
            className="WebinarTabsMobile-btn"
            onClick={() => onNavigateDate(tabActiveIndex + 1)}
          >
            <span className="Icon-ic Icon-arrow-right WebinarTabsMobile-icon" />
          </span>
        )}
      </div>
    </div>
  )
}
