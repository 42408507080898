import React from 'react'
import { isEmpty } from 'lodash'
import { Container } from '@material-ui/core'
import Tabs from 'components/widgets/Tabs'
import WebinarTabsMobile from './WebinarTabsMobile'
import './index.scss'

export default function WebinarTabs(props) {
  const {
    listDates = [],
    tabActiveIndex = 0,
    setTabActiveIndex = () => {},
    setSelectedDate = () => {},
  } = props

  /**
   * Change tab select date
   *
   * @param {DOM Event} event DOM Event
   * @param {Integer} index Tab index
   */
  function onChangeTab(event, index) {
    const selectedDate = listDates[index] || {}
    const { value = '' } = selectedDate

    setTabActiveIndex(index)
    setSelectedDate(value)
  }

  if (isEmpty(listDates)) {
    return null
  }

  return (
    <Container className="Webinars-tabs_wrapper">
      <Tabs
        tabs={listDates}
        tabActiveIndex={tabActiveIndex}
        handleChangeTab={onChangeTab}
        className="Webinars-tabsCustom Webinars-tabsUI"
        hasScroll
      />
      <WebinarTabsMobile {...props} />
    </Container>
  )
}
