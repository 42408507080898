/* eslint-disable max-len */
import React, { useState, useMemo, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container, useMediaQuery } from '@material-ui/core'
import Button from 'components/widgets/Button'
import {
  getEventTime,
  formatPositionAndOrgName,
  isLiveEventStatus,
} from 'utils'
import {
  getFeaturedImg,
  getFeatureWebinarTitle,
  getWebinarVideoURL,
} from 'utils/common'
import { pageEvent } from 'utils/tracker'
import { TRACKING, FILE_CODES } from 'config/constants/common'
import { DEFAULT_TIMEZONE } from 'config/constants/timezone'
import './index.scss'

const { timeCode: defaultTimeCode } = DEFAULT_TIMEZONE

export default function FeaturedWebinar(props) {
  const { t } = useTranslation('common')
  const [isLiveEvent, setLiveEvent] = useState(false)
  const { data, isUpdateEvent } = props
  const {
    webinarId,
    webinarName = '',
    panelId,
    ecmsUserByHostName,
    ecmsEventFilesByWebinarId: filesAttach = [],
    // ecmsLanguageByLanguageCode,
    startDateTimezone,
    endDateTimezone,
    zoomLink = '',
  } = data || {}
  const { displayName: userName = '', position = '', ecmsOrganisationByOrgId } =
    ecmsUserByHostName || {}
  const { orgName = '' } = ecmsOrganisationByOrgId || {}
  // const { languageName = '' } = ecmsLanguageByLanguageCode || {}
  const trackingEventSource = `${TRACKING.SOURCE.WEBINARS}_${TRACKING.SOURCE.WEBINAR_DETAIL}${webinarId}`
  const isMobile = useMediaQuery('(max-width:480px)')

  // Get featured image
  const { featuredImg, snapShootImg } = useMemo(() => {
    const featuredImg = getFeaturedImg(filesAttach, [FILE_CODES.FE1], { defaultImage: null })
    const snapShootImg = getFeaturedImg(filesAttach, [FILE_CODES.FE3], { defaultImage: null })
    return {
      featuredImg,
      snapShootImg: snapShootImg || featuredImg,
    }
  }, [filesAttach])

  const featuredImgPanel = useMemo(() => {
    return getFeaturedImg(filesAttach, [FILE_CODES.FE3])
  }, [filesAttach])


  // get webinar video URL
  const webinarVideoURL = useMemo(() => {
    return getWebinarVideoURL(zoomLink)
  }, [zoomLink])

  // GET EVENT TIME
  const eventTime = useMemo(() => {
    return getEventTime(startDateTimezone, endDateTimezone)
  }, [startDateTimezone, endDateTimezone])

  // GET webinar title
  const webinarTitle = useMemo(() => {
    return getFeatureWebinarTitle(webinarName)
  }, [webinarName])

  // Run every minute to update state
  useEffect(() => {
    updateEventTimeLeft()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEvent, startDateTimezone, endDateTimezone])

  /**
   * Update timeleft
   */
  function updateEventTimeLeft() {
    // get event status
    const isLive = isLiveEventStatus(startDateTimezone, endDateTimezone)
    setLiveEvent(isLive)
  }

  /**
   * Go to panel detail by panelId
   */
  function goToPanelDetail() {
    if (!panelId) return

    navigate(`/exhibitions/${panelId}`, { state: { index: 0 } })
  }

  /**
   * On click join zoom meeting
   */
  function joinZoom() {
    pageEvent(trackingEventSource, 'video')
  }

  return (
    <div className="FeaturedWebinar-container">
      <Container>
        <div className="FeaturedWebinar-img">
          <div className="FeaturedWebinar-img_container">
            {(featuredImg || (isMobile && snapShootImg)) && (
              <div
                className="FeaturedWebinar-img_content"
                style={{ backgroundImage: `url(${isMobile ? snapShootImg : featuredImg})` }}
               />
            )}
            {/** START UPDATE CHANGE REQUEST [Display the webinar on the top banner] ON 2021-08-30 */}
            <div className="FeaturedWebinar-img_container_time_hold">
              {(isLiveEvent && featuredImg && !isMobile) && (
                <div
                  className="FeaturedWebinar-img_content_time_hold"
                  style={{ backgroundImage: `url(${featuredImgPanel})` }}
                />
              )}
            </div>
            {/** END UPDATE CHANGE REQUEST [Display the webinar on the top banner] ON 2021-08-30 */}
            <h4 className="FeaturedWebinar-time sp-view">
              <span>
                {eventTime}&nbsp;<span className="FeaturedWebinar-timecode">{defaultTimeCode}</span>
              </span>
              {/* {languageName && <span className="FeaturedWebinar-lang sp-view">{languageName}</span>} */}
            </h4>
            <h3 className="FeaturedWebinar-title sp-view">{webinarTitle}</h3>
          </div>
        </div>
        <div className="FeaturedWebinar-content">
          <div className="FeaturedWebinar-upper">
            <div className="FeaturedWebinar-timeContent_wrapper">
              <div className="FeaturedWebinar-timeContent">
                <h4 className="FeaturedWebinar-time pc-view">
                  {eventTime}&nbsp;
                  <span className="FeaturedWebinar-timecode">{defaultTimeCode}</span>
                </h4>
                {isLiveEvent && (
                  <span className={`FeaturedWebinar-status ${isLiveEvent && 'live'}`}>
                    {t('common:lbl_live_en')}
                  </span>
                )}
                <Button
                  label={t('webinars:btn_webinar_video_en')}
                  className="FeaturedWebinar-btn"
                  onClick={joinZoom}
                  disabled={!webinarVideoURL}
                  href={webinarVideoURL}
                  target="_blank"
                />
                {panelId && (
                  <Button
                    className="FeaturedWebinar-btn FeaturedWebinar-btn_panel sp-view"
                    onClick={goToPanelDetail}
                  >
                    {t('webinars:lbl_go_to_panel_en')}
                  </Button>
                )}
              </div>
            </div>
            <div className="FeaturedWebinar-intro">
              <h3 className="FeaturedWebinar-title pc-view">{webinarTitle}</h3>
              <div className="FeaturedWebinar-groupAction">
                <div className="FeaturedWebinar-groupAction_wrapper">
                  {panelId && (
                    <Button
                      className="FeaturedWebinar-btn FeaturedWebinar-btn_panel"
                      onClick={goToPanelDetail}
                    >
                      {t('webinars:lbl_go_to_panel_en')}
                    </Button>
                  )}
                  {/* {languageName && <span className="FeaturedWebinar-lang">{languageName}</span>} */}
                </div>
              </div>
            </div>
          </div>
          <div className="FeaturedWebinar-info">
            <h4 className="FeaturedWebinar-username">{userName}</h4>
            <p>{formatPositionAndOrgName(position, orgName, ',\n')}</p>
          </div>
        </div>
      </Container>
    </div>
  )
}
