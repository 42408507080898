/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { isArray, isEmpty } from 'lodash'
import Button from 'components/widgets/Button'
import ButtonActions from 'components/widgets/Interactive/ButtonActions'
import { useAuth } from 'store/Auth'
import {
  formatPositionAndOrgName,
  getEventTimeDesc,
  getEventTime,
  isLiveEventStatus,
} from 'utils'
import formatNumber, {
  getFeaturedImg,
  getWebinarVideoURL,
} from 'utils/common'
import { pageEvent } from 'utils/tracker'
import {
  FAVORITE_TYPES,
  WEBINAR_TYPES,
  TRACKING,
  FILE_CODES,
  IS_HIDDEN_VIEWS,
  IS_USE_DOWNLOAD,
  // MAP_LANGUAGES_BY_DB,
} from 'config/constants/common'
import { ASSOCIATION } from 'config/constants/file'
import './index.scss'

export default function WebinarItem(props) {
  const { t } = useTranslation(['webinars', 'common'])
  const [isLiveEvent, setLiveEvent] = useState(false)
  const { clientTzData } = useAuth()
  const { webinar, index, isUpdateEvent, views = 0, featuredWebinarTimeHold } = props
  const {
    webinarId,
    webinarName = '',
    ecmsUserByHostName,
    ecmsEventFilesByWebinarId: filesAttach = [],
    panelId,
    // languageCode = '',
    webinarType,
    startDateTimezone,
    endDateTimezone,
    zoomLink = '',
  } = webinar || {}
  const { displayName: userName = '', position = '', ecmsOrganisationByOrgId } =
    ecmsUserByHostName || {}
  const { orgName = '' } = ecmsOrganisationByOrgId || {}
  const filesDownload =
    filesAttach.filter((file) => {
      const { fileType, keyCode } = file || {}

      return fileType === ASSOCIATION.WEBINAR && keyCode === FILE_CODES.ATT
    }) || []
  const isDownload = isArray(filesDownload) && !isEmpty(filesDownload)
  const { timeCode: clientTimeCode = '', utcOffsetStr: clientTzOffset = '' } = clientTzData || {}
  const trackingEventSource = `${TRACKING.SOURCE.WEBINARS}_${TRACKING.SOURCE.WEBINAR_DETAIL}${webinarId}`

  // Get event time
  const { eventTime, eventTimeDesc } = useMemo(() => {
    return {
      eventTime: getEventTime(startDateTimezone, endDateTimezone),
      eventTimeDesc: getEventTimeDesc(clientTzOffset, clientTimeCode),
    }
  }, [startDateTimezone, endDateTimezone, clientTzOffset, clientTimeCode])

  // Get featured image
  const featuredImg = useMemo(() => {
    return getFeaturedImg(filesAttach, [FILE_CODES.FE3])
  }, [filesAttach])

  // get webinar video URL
  const webinarVideoURL = useMemo(() => {
    return getWebinarVideoURL(zoomLink)
  }, [zoomLink])

  // Get language code by data
  // const langCode = useMemo(() => {
  //   return MAP_LANGUAGES_BY_DB[languageCode]
  // }, [languageCode])

  // Run every minute to update state
  useEffect(() => {
    updateEventTimeLeft()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEvent, startDateTimezone, endDateTimezone])

  /**
   * Update timeleft
   */
  function updateEventTimeLeft() {
    // get event status
    const isLive = isLiveEventStatus(startDateTimezone, endDateTimezone)
    setLiveEvent(isLive)
  }

  /**
   * View webinar detail
   *
   * @param {Integer} webinarId Webinar id
   * @param {Integer} index Webinar index
   */
  function goToWebinarDetail(index) {
    return
    // return navigate(`/webinars/${webinarId}`, { state: { index } })
  }

  /**
   * Go to panel detail by panelId
   */
  function goToPanelDetail() {
    if (!panelId) return

    navigate(`/exhibitions/${panelId}`, { state: { index: 0 } })
  }

  /**
   * On click join zoom meeting
   */
  function joinZoom() {
    pageEvent(trackingEventSource, 'video')
  }

  return (
    <div className="WebinarItem-container">
      <div className="WebinarItem-info">
        <p className="WebinarItem-time">
          <span>
            <span>{eventTime}&nbsp;</span>
            <span className="WebinarItem-timezone">{eventTimeDesc}</span>
          </span>
          <Button
            label={t('webinars:btn_webinar_video_en')}
            className="WebinarItem-btn WebinarItem-btn_session"
            onClick={joinZoom}
            disabled={!webinarVideoURL}
            href={webinarVideoURL}
            target="_blank"
          />
          {/** START UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */}
          {/* {isLiveEvent && featuredWebinarTimeHold === webinarId && (            */}
          {isLiveEvent && (   
          <>
            <span className="WebinarItem-live_time_hold"/>
            <span className="WebinarItem-live_time_hold">{t('common:lbl_live_en').toLocaleUpperCase()} </span>
          </>
          )}
          {/** END UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */}
        </p>
        <h4 className="WebinarItem-title pc-view" onClick={() => goToWebinarDetail(index)}>
          {webinarName}
        </h4>
      </div>
      <div className="WebinarItem-content">
        <div className="WebinarItem-ava">
          <div className="WebinarItem-ava_img" style={{ backgroundImage: `url(${featuredImg})` }} />
        </div>
        <h4 className="WebinarItem-title sp-view" onClick={() => goToWebinarDetail(index)}>
          {webinarName}
        </h4>
        {/* <div className="WebinarItem-desc sp-view">
          {langCode && <span className="WebinarItem-actions_desc lang">{langCode}</span>}
        </div> */}
        <div className="WebinarItem-user">
          <div className="WebinarItem-user_info">
            <h4 className="WebinarItem-userName">{userName}</h4>
            <p className="WebinarItem-organization">
              {formatPositionAndOrgName(position, orgName)}
            </p>
          </div>
          <div className="WebinarItem-actions">
            {/* <div className="WebinarItem-desc pc-view">
              {langCode && <span className="WebinarItem-actions_desc lang">{langCode}</span>}
            </div> */}
            <div className="WebinarItem-actions_group">
              <div
                className={`WebinarItem-analysis_wrapper ${
                  IS_HIDDEN_VIEWS && !IS_USE_DOWNLOAD && 'hidden'
                }`}
              >
                {!IS_HIDDEN_VIEWS && (
                  <span className="WebinarItem-analysis only-view">
                    <strong>{formatNumber(views)}</strong>&nbsp;
                    <span>{t('common:lbl_views_en')}</span>
                  </span>
                )}
                <ButtonActions
                  type={FAVORITE_TYPES.WEBINAR}
                  isDownload={isDownload}
                  filesDownload={filesDownload}
                  webinarId={webinarId}
                  trackingEventSource={TRACKING.SOURCE.WEBINARS}
                  hideAddRemove
                />
              </div>
              <div className="WebinarItem-panel">
                {/** START UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */}
                {/* <div className="WebinarItem-status">
                  {isLiveEvent && (
                    <>
                      <span className={`WebinarItem-status_icon ${isLiveEvent && 'live'}`} />
                      {t('common:lbl_live_en')}
                    </>
                  )}
                </div> */}
                {/** END UPDATE CHANGE REQUEST [Show "LIVE" on the webinar] ON 2021-08-30 */}
                <Button
                  label={t('webinars:btn_webinar_video_en')}
                  className="WebinarItem-btn WebinarItem-btn_session sp-view"
                  onClick={joinZoom}
                  disabled={!webinarVideoURL}
                  href={webinarVideoURL}
                  target="_blank"
                />
                {webinarType !== WEBINAR_TYPES.NON_PANEL && (
                  <Button
                    className="WebinarItem-btn WebinarItem-btn_panel"
                    label={t('lbl_go_to_panel_en')}
                    onClick={goToPanelDetail}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
